import React                                                                                     from 'react';
import moment                                                                                    from 'moment';
import ReactHtmlParser                                                                           from 'react-html-parser';
import {withSnackbar}                                                                            from 'notistack';
import swal                                                                                      from 'sweetalert';
import { serialize }                                                                             from 'object-to-formdata';
import Grid                                                                                      from '@material-ui/core/Grid';
import FormHelperText                                                                            from '@material-ui/core/FormHelperText';
import TextField                                                                                 from '@material-ui/core/TextField';
import Button                                                                                    from '@material-ui/core/Button';
import Hidden                                                                                    from '@material-ui/core/Hidden';
import Divider                                                                                   from '@material-ui/core/Divider';
import Paper                                                                                     from '@material-ui/core/Paper';
import Typography                                                                                from '@material-ui/core/Typography';
import RadioGroup                                                                                from '@material-ui/core/RadioGroup';
import Radio                                                                                     from '@material-ui/core/Radio';
import FormControlLabel                                                                          from '@material-ui/core/FormControlLabel';
import FormControl                                                                               from '@material-ui/core/FormControl';
import FormGroup                                                                                 from '@material-ui/core/FormGroup';
import Checkbox                                                                                  from '@material-ui/core/Checkbox';
import Avatar                                                                                    from '@material-ui/core/Avatar';
import TopNav                                                                                    from 'components/molecules/TopNav';
import Tooltip                                                                                   from '@material-ui/core/Tooltip';
import InfoIcon                                                                                  from '@material-ui/icons/InfoOutlined';
// import VideoRecorder                                                                             from 'components/molecules/VideoRecorder';
import MobileNav                                                                                 from 'components/molecules/MobileNav';
import Loader                                                                                    from 'components/atoms/Loader';
import apis                                                                                      from 'common/apis';
import Log                                                                                       from 'common/log';
import utility                                                                                   from 'common/utility';
import {errorMsg}                                                                                from 'common/errors';
import translations                                                                              from 'common/translations';
import AutoSelect                                                                                from 'components/atoms/AutoSelect';
import profileStore, {applicantProfile, applicantEditables, saveProfile, applicantProfileFields} from 'redux/profileStore';
import commonStore, {changeLanguage}                                                             from 'redux/commonStore';
import { light }                                                                                 from 'styles/muiTheme/colors';
import Skeleton                                                                                  from './Skeleton';
import {styles}                                                                                  from './style';
import VimeoVideoUploader                                                                        from 'components/atoms/VimeoVideoUploader';

const log = Log('Profile', 'info');

class Profile extends React.Component {

	constructor (props) {
		super (props);


		this.state = {
			loading     : {
				profile : true,
				save    : false,
				edit    : false,
			},
			profileInfo           : null,
			editFields            : null,
			mobileNav             : false,
			editable              : false,
			binaryData            : null,
			fileSize              : null,
			offset                : 0,
			videoLink             : '',
			errors                : null,
			videoUploading        : false,
			can_edit_pr_video     : false,
			documentFields        : [],
			downloadDoc           : null,
			langCode              : 'en',
			profileFields         : null,
			photoSrc              : null,
			photograph            : null,
			pr_video_instructions : '',
			universities          : [],
			education_backgrounds : {},
			faculties             : {},
			name                   : {value : '', error : false},
			japanese_name          : {value : '', error : false},
			applicantUniversities  : [],
			universityError        : [],
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		this.commonUnsub = commonStore.subscribe (this.getLanguage);
		this.getLanguage ();
		this.getProfileFields ();
		this.getProfileInfo ();
		this.getDownloadList ();
		this.getUniversity ();
	}

	componentWillUnmount = () => {
		
		this.commonUnsub ();
	}

	getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		this.setState ({
			langCode
		});
	}

	handleChangeName = (event) => {
		const {name, value} = event.currentTarget;
		this.setState ({
			[name] : {
				value : value,
				error : false,
			}
		});
	}

	getUniversity = async () => {

		let result;
		try {
			result = await apis.getUniversityList ();
			log.info ({universities : result}, 'get country list');
		}
		catch (err) {
			log.error ({err}, 'error getting country list');
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		let universities = [...result.universities];
		let education_backgrounds = result.education_backgrounds;
		let faculties = result.faculties;
		this.setState ({
			universities,
			education_backgrounds,
			faculties,
		});
	}

	handleUniversityFieldSelChange = (name, value, id) => {
		let _applicantUniversities = this.state.applicantUniversities;
		let _univeristy = _applicantUniversities.find(uni => uni.id === id);
		if (name === 'is_latest') {
			_applicantUniversities = _applicantUniversities.map(uni => (
				{
					...uni,
					is_latest : uni.id === id ? true : false
				}
			));
		}
		/**For these fields we are directly set the value to that field */
		if (name === 'is_latest' || name === 'department' || name === '_destroy' || name === 'other_university_name')
			_univeristy[name] = value;
		/**These fields are for dropdowns*/
		else {
			_univeristy[name] = value?.value;
		}

		if (name === 'university_id' && value.label?.toLowerCase().includes('other')) {
			_univeristy['otherUniversity'] = true;
		}
		if (name === 'university_id' && !value.label?.toLowerCase().includes('other')) {
			_univeristy['otherUniversity'] = false;
			_univeristy['other_university_name'] = '';
		}
		if ( name === 'other_university_name') {
			_univeristy['otherUniversity'] = true;
		}
		this.setState({
			applicantUniversities : [..._applicantUniversities],
		});
	}

	addEducation = () => {
		let uniData = {
			university_id        : null,
			faculty              : null,
			education_background : null,
			department           : '',
			start_month          : null,
			start_year           : null,
			graduated_month      : null,
			graduated_year       : null,
			_destroy             : false,
			is_latest            : false,
			id                   : utility.generateRandomInteger(5),
			new                  : true,
		};
		this.setState ({
			applicantUniversities : [...this.state.applicantUniversities, uniData]
		});
	}

	handleRemoveEducation = (id) => {
		let _applicantUniversities = this.state.applicantUniversities;
		let _univeristy = _applicantUniversities.find(uni => uni.id === id);
		if (_univeristy.id) {
			_univeristy['_destroy'] = true;
		}
		else {
			_applicantUniversities = _applicantUniversities.filter(uni => uni.id === id);
		}
		this.setState ({
			applicantUniversities : [..._applicantUniversities],
		});
	}

	handleTranslation = (lan) => {
		if (this.state.profileInfo['is_fastoffer']) {
			commonStore.dispatch(changeLanguage (lan));
		}
		else {
			this.setState ({
				langCode : lan,
			});
		}
	}

	handleDocChange = (event, id) => {

		let files    = event.target.files[0];
		if (!files) {
			return;
		}
		let __documents = this.state.documentFields;
		let __documentIndex = __documents.findIndex (d => d.document_name_id === id);
		if (__documentIndex < 0) {
			return;
		}
		let __document = __documents[__documentIndex];

		const fileSize = event.target.files[0].size;
		if (fileSize > 10*1024*1024) { // files size should not be greater than 10 mb
			__document = {
				...__document,
				errors : true,
			};
			__documents[__documentIndex] = __document;
			this.setState ({
				documentFields : __documents,
			});
			return;
		}
		__document = {
			...__document,
			value : files,
			errors : false,
		};
		__documents[__documentIndex] = __document;
		this.setState ({
			documentFields : __documents,
		});
	}

	handlePhotoChange = (event) => {

		let files    = event.target.files[0];
		if (!files) {
			return;
		}
		const fileSize = event.target.files[0].size;
		if (fileSize > 2*1024*1024) { // files size should not be greater than 10 mb
			this.notify ('Image size should be less than 2MB', {variant : 'error'});
			return;
		}
		this.setState ({
			photoSrc   : URL.createObjectURL(files),
			photograph : files,
		});
	}

	handleEditAbleFields = (event) => {
	
		const {name, value} = event.target;
		let customFields    = this.state.editFields.custom_fields;

		this.setState ({
			editFields : {
				...this.state.editFields,
				custom_fields : {
					...customFields,
					[name] : value
				}
			}
		});
	}

	getProfileInfo = async () => {
		
		let profileInfo = profileStore.getState ();
		let profile     = profileInfo.profile;
		
		this.setState ({
			loading : {
				...this.state.loading,
				profile : true,
			},
		});
		try {
			profile = await apis.getProfile ();
			log.info ({profile}, 'profile info get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting profile info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}

			this.setState ({
				loading : {
					...this.state.loading,
					profile : false,
				},
				profileInfo : profile,
			});
			return;
		}
		profileStore.dispatch (applicantProfile (profile));
		this.setState ({
			loading : {
				...this.state.loading,
				profile : false,
			},
			profileInfo : profile,
			applicantUniversities : profile?.universities || [],
		});
		return;
	}

	getDownloadList = async () => {
		
		let downloadDoc;
		try {
			downloadDoc = await apis.getDownloadList ();
			log.info ({downloadDoc}, 'download doc list get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting download doc list');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
		}
		this.setState ({
			downloadDoc : downloadDoc.downloadable_documents
		});
	}

	getProfileFields = async () => {
		
		let profileInfo = profileStore.getState ();
		let fields = profileInfo.profileFields;

		if (fields) {
			this.setState ({
				profileFields : fields,
			});
			return;
		}

		this.setState ({
			loading : {
				...this.state.loading,
				profile : true,
			},
		});
		try {
			fields = await apis.getProfileFields ();
			log.info ({fields}, 'fields get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting profile fields');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : {
					...this.state.loading,
					profile : false,
				},
			});
		}
		profileStore.dispatch (applicantProfileFields (fields.custom_fields));
		this.setState ({
			profileFields : fields.custom_fields,
			loading : {
				...this.state.loading,
				profile : false,
			}
		});
	}

	onEdit = async() => {
		
		let profileInfo = profileStore.getState ();
		let editFields  = profileInfo.editableFields;
		let documentFields = profileInfo.editableFields ? profileInfo.editableFields.document_fields : [];

		let _profileInfo = this.state.profileInfo;
		let _univ = this.state.universities;
		let _bach_univ = _univ.find (uv => uv.id === _profileInfo.bachelor_university_id);
		let _mast_univ = _univ.find (uv => uv.id === _profileInfo.master_university_id);

		this.setState ({
			loading : {
				...this.state.loading,
				edit : true,
			}
		});
		let result;
		try {
			result = await apis.editProfile ();
			log.info ({editFields : result} , 'edit fields get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting edit profile info');
			if (err.response?.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : {
					...this.state.loading,
					edit : false,
				}
			});
			return;
		}
		editFields = this.getCustomisedFields (result.custom_fields);
		documentFields = this.getCustomizedDocumentFields (result.document_fields);
		profileStore.dispatch (applicantEditables (result));
		this.setState({
			loading : {
				...this.state.loading,
				edit : false,
			},
			editable : true,
			name : {value : _profileInfo.name, error : false},
			japanese_name : {value : _profileInfo.japanese_name, error : false},
			editFields : editFields.customFields,
			documentFields : documentFields,
			errors : editFields.customErrors,
			can_edit_pr_video : result.can_edit_pr_video,
			pr_video_instructions :result.pr_video_instructions,
			master_university_id : _mast_univ ? {label : _mast_univ.name, value : _mast_univ.id} : null,
			bachelor_university_id : _bach_univ ? {label : _bach_univ.name, value : _bach_univ.id} : null,
		});
		return;
	}

	getCustomizedDocumentFields = (document_fields) => {
		document_fields = document_fields.map (item => {
			return {
				...item,
				value : null,
				errors : false,
			};
		});
		return document_fields;
	}

	getCustomisedFields = (custom_fields) => {

		let __customFields = Object.keys(custom_fields);
		let profileCustomFields = this.state.profileInfo.custom_fields;
		let customFields = {};
		let customErrors = {};

		for (let i = 0; i < __customFields.length; i++) {
			let __inputType = custom_fields[__customFields[i]].input_type;
			let __options   = custom_fields[__customFields[i]].options;
			let value;
			for (let j = 0; j < profileCustomFields.length; j++) {
				if (profileCustomFields[j].fields[__customFields[i]]) {
					value = profileCustomFields[j].fields[__customFields[i]];
				}
			}
			customFields[__customFields[i]] = {
				...custom_fields[__customFields[i]],
				value   : value ? value : __inputType === 'check_box' ? [] : '',
				options : __options.length ? __options.map ((item, index) => {
					return {
						name : item,
						id   : index
					};
				})  : __options
			};
			customErrors[__customFields[i]] = false;
		}
		return {customFields, customErrors};
	}

	handlePRVideo = (fieldName, videoUrl) => {
		this.setState ({
			videoLink : videoUrl
		});
	}

	handleVideoCustomFields = (fieldName, videoUrl) => {

		this.setState ({
			editFields : {
				...this.state.editFields,
				[fieldName] : {
					...this.state.editFields[fieldName],
					value : videoUrl,
				},
			},
			errors : {
				...this.state.errors,
				[fieldName] : false,
			}
		});
	}

	handleCustomFields = (event) => {

		const {name, value}  = event.currentTarget;

		this.setState ({
			editFields : {
				...this.state.editFields,
				[name] : {
					...this.state.editFields[name],
					value : value,
				},
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handlePermanentSelect = (name, value) => {

		this.setState ({
			permanentFields : {
				...this.state.permanentFields,
				[name] : value
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleCustomSelect = (name, value) => {

		this.setState ({
			editFields : {
				...this.state.editFields,
				[name] : {
					...this.state.editFields[name],
					value : value,
				}
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleCustomCheckbox = (event) => {

		const {name, checked, value} = event.target;

		this.setState ({
			editFields : {
				...this.state.editFields,
				[name] : {
					...this.state.editFields[name],
					value : checked ? [...this.state.editFields[name].value, value] : this.state.editFields[name].value.filter (v => v!== value)
				}
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	getDropDownValue = (value) => {
		if (!value) {
			return null;
		}
		if (value['label']) {
			return value.label;
		}
		return value;
	}

	onSaveClick = () => {
		swal ({
			title   : 'Save Profile',
			text    : 'Are you sure, you want to save all your changes?',
			icon    : 'info',
			buttons : ['Cancel','Ok']
		}).then((res) => {
			if (res) {
				this.onSave();
			}
		});
	}

	onSave = async () => {
			
		let customFields   = this.state.editFields;
		let documentFields = this.state.documentFields;
		let applicantId    = this.state.profileInfo.id;
		let __customFields  = Object.keys(customFields);
		let customFieldsData = {};
		let applicantUniversities = this.state.applicantUniversities;
		let errors = {};

		if (!utility.validateTextField (this.state.name.value)) {
			errors['name'] = true;
			this.setState ({
				name : {
					...this.state.name,
					error : true,
				}
			});
		}

		let _universityError = [];

		applicantUniversities.forEach(uni => {
			if (uni._destroy) {
				return;
			}
			let error = {
				university_id : false,
				faculty : false,
				education_background : false,
				start_month : false,
				start_year : false,
				graduated_month : false,
				graduated_year : false,
				otherUniversity : false,
			};
			Object.keys(error).forEach(key => {
				if (!uni[key] && key !== 'otherUniversity') {
					error[key] = true;
					errors['applicant_universities_attributes'] = true;
				}
				if (uni.otherUniversity && (!uni.other_university_name || uni.other_university_name.trim() === "")) {
					error['otherUniversity'] = true;
					errors['applicant_universities_attributes'] = true;
				}
			});
			_universityError.push(error);
		});

		for (let i = 0; i < __customFields.length; i++) {
			let __inputType   = customFields[__customFields[i]].input_type;
			let __isMandatory = customFields[__customFields[i]]['is_mandatory?'];
			let __value = __inputType === 'drop_down' ? this.getDropDownValue(customFields[__customFields[i]].value) : customFields[__customFields[i]].value;
			if (Array.isArray (__value) && !__isMandatory) {
				let _options = customFields[__customFields[i]].options || [];
				__value.filter (v => _options.includes (v));
				customFieldsData[__customFields[i]] = __value;
				continue;
			}
			if (!__isMandatory) {
				customFieldsData[__customFields[i]] = __value;
				continue;
			}
			switch (__inputType) {
				case 'text' :
					if (!utility.validateTextField (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'number' :
					if (!utility.validateNumber (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'date' :
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'email' :
					if (!utility.validateEmail (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'phone_number' :
					if (!utility.validatePhoneNumber (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'url' :
					if (!utility.validateUrl (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'location' :
					if (!utility.validateTextField (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'drop_down':
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'text_area':
					if (!utility.validateTextField (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'currency' :
					if (!utility.validateCurrency (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'check_box' : {
					if (!__value.length) {
						errors[__customFields[i]] = true;
						break;
					}
					if (typeof __value === 'string') {
						__value = __value.split (',');
						__value = __value.map(v => v.trim());
					}
					let _options = customFields[__customFields[i]].options || [];
					__value.filter (v => _options.includes (v));
					customFieldsData[__customFields[i]] = __value;
					break;
				}
				case 'radio_button':
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'video' :
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				default :
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
			}
		}

		/*Return if there is any error field*/

		if (Object.keys (errors).length) {
			this.setState ({
				errors : {
					...this.state.errors,
					...errors,
				},
				universityError : _universityError
			});
			this.notify ('Please fill all the mandatory fields.', {variant : 'error'});
			log.info ({errors});
			return;
		}

		let documentError = false;
		let document_attributes = {};
		for (let i = 0; i < documentFields.length; i++) {
			if (documentFields[i]['is_mandatory?'] && !documentFields[i].value) {
				documentFields[i].errors = true;
				documentError = true;
				continue;
			}
			let upload = documentFields[i].value;
			if (!upload) {
				continue;
			}
			document_attributes = {
				...document_attributes,
				[documentFields[i].document_name_id] : {
					document_name_id : documentFields[i].document_name_id,
					upload : upload,
				}
			};
		}

		if (documentError) {
			this.notify ('Please upload all the required documents.',{variant : 'error'});
			this.setState ({
				documentFields
			});
			return;
		}

		let uniCount = applicantUniversities.filter(uni => !uni._destroy)?.length;
		if (uniCount < 1) {
			this.notify ('Please add atleast one education.',{variant : 'error'});
			return;
		}

		let _applicantUniversities = applicantUniversities.map (uni => {
			let _data = {
				university_id        : uni.university_id,
				faculty              : uni.faculty,
				education_background : uni.education_background,
				department           : uni.department,
				start_month          : uni.start_month,
				start_year           : uni.start_year,
				graduated_month      : uni.graduated_month,
				graduated_year       : uni.graduated_year,
				other_university_name: uni.other_university_name,
				_destroy             : uni._destroy || false,
				is_latest            : uni.is_latest || false,
			};
			/**for new added education */
			if (uni.new) {
				return _data;
			}
			/**To update education */
			return {
				..._data,
				id : uni.id,
			};
		});

		let jsonData = {
			applicant : {
				name : this.state.name.value,
				japanese_name : this.state.japanese_name.value,
				photograph : this.state.photograph || undefined,
				applicant_universities_attributes : _applicantUniversities,
				custom_fields : {
					...customFieldsData,
				},
				documents_attributes: {
					...document_attributes,
				}
			}
		};

		if (this.state.videoLink) {
			jsonData['applicant']['pr_video'] = this.state.videoLink;
		}

		log.info ({data : jsonData}, 'data sent');

		let data = serialize ({
			...jsonData,
		}, {allowEmptyArrays: true,});

		this.setState ({
			loading : {
				...this.state.loading,
				save : true,
			}
		});

		let result;

		try {
			result = await apis.saveProfile (data, applicantId);
			log.info ({profile : result}, 'get save profile');
		}
		catch (err) {
			log.error ({err}, 'error updating applicant profile');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify (err?.response?.data?.error, {variant : 'error'});
			this.setState ({
				loading : {
					...this.state.loading,
					save : false,
				},
				can_edit_pr_video : false,
			});
			return;
		}
		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				loading : {
					...this.state.loading,
					save : false,
				},
				can_edit_pr_video : false,
			});
			return;
		}
		this.setState ({
			loading : {
				...this.state.loading,
				save : false,
			},
			editable : false,
			can_edit_pr_video : false,
			universityError : [],
			videoLink : '',
		});
		this.notify (result.message, {variant : 'success'});
		profileStore.dispatch (saveProfile (result.custom_fields));
		this.getDownloadList ();
		this.getProfileInfo ();
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	onCancel = () => {
		
		this.setState ({
			editable : false,
			photograph : null,
			photoSrc : null,
		});
	}

	renderActionButtons = () => {
		let langCode = this.state.langCode;
		if (!this.state.editable) {
			return (
				<Button variant = 'contained' color = 'secondary' onClick = {this.onEdit}>
					{this.state.loading.edit ? <Loader color = 'primary' /> : translations.profile.edit[langCode]}
				</Button>
			);
		}

		return (
			<Grid container justify = 'flex-end' className = 'mt-12'>
				<Button variant = 'outlined' className = 'mr-16' onClick = {this.onCancel}> {translations.profile.cancel[langCode]} </Button>
				{/* {this.state.can_edit_pr_video ?
					<Button
						variant  = 'contained'
						color    = 'primary'
						id       = "save-profile"
						disabled = {this.state.loading.save || this.state.videoUploading}>
						{this.state.loading.save ? <Loader color = 'secondary'/> : translations.profile.save[langCode]}
					</Button>
					: */}
				<Button
					variant  = 'contained'
					color    = 'primary'
					onClick  = {this.onSaveClick}
					disabled = {this.state.loading.save || this.state.videoUploading}>
					{this.state.loading.save ? <Loader color = 'secondary'/> : translations.profile.save[langCode]}
				</Button>
				{/* } */}
			</Grid>
		);
	}

	renderRadio = () => {

		return (
			<Radio color = 'primary' size = 'small'/>
		);
	}

	renderDocumentFields = () => {
		
		if (!this.state.editable) {
			return;
		}
		let _documentFields = this.state.documentFields;
		let __documents = [];
		_documentFields.map (documents => (
			__documents.push (
				<Grid container spacing = {2} alignItems = 'center' key = {documents.document_name_id}>
					<Grid container item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
						<Typography variant = 'subtitle2'> {documents.document_name} </Typography>
					</Grid>
					<Grid container item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8' alignItems = 'center'>
						<TextField
							type       = 'file'
							name       = {documents.document_name}
							error      = {documents.errors}
							onChange   = {(ev) => this.handleDocChange(ev, documents.document_name_id)}
							helperText = {document.errors ?
								"Doc size is greater than 10"
								:
								"Upload docx, pdf, png/jpeg, pptx or txt of max size 10mb"}
							inputProps = {{
								accept : '.pdf,.txt,.pptx,.doc,.docx,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
							}}
						/>
					</Grid>
				</Grid>

			)
		));
		return __documents;
	}

	renderValues = (field, value) => {
		
		let langCode = this.state.langCode;

		if (this.state.editable) {
			let editCustomFields = this.state.editFields;
			let customField = Object.prototype.hasOwnProperty.call(editCustomFields, field) ? editCustomFields[field] : value;
			if (!customField) {
				return;
			}
			let inputType = customField.input_type;
			let errors = this.state.errors;
			let placeholder = customField[langCode === 'en' ? 'eng_placeholder' : 'ja_placeholder'] || customField['translated_name'];
			
			if (field === 'id' || field === 'email') {
				return (
					<TextField
						value       = {value}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						disabled
						fullWidth
					/>
				);
			}

			if (field === 'name' || field === 'japanese_name') {
				return (
					<TextField
						value       = {this.state[field]?.value}
						error       = {field === 'name' ? this.state.name.error : false}
						variant     = 'outlined'
						name        = {field}
						type        = 'text'
						onChange    = {this.handleChangeName}
						fullWidth
					/>
				);
			}

			switch (inputType) {

				case 'text' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'text'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'number' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'number'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'date' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'date'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'email' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'email'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'phone_number' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'text'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'url' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'url'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'location' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'text'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'drop_down': {
					// let value;
					// if (!customField.value['label']) {
					// 	let selected = customField.options.find (item => item.name === customField.value);
					// 	value = selected ? {label : selected.name, value : selected.id} : null;
					// }
					// else {
					// 	value = customField.value;
					// }
					return (
						<AutoSelect
							value       = {customField.value}
							error       = {errors[field]}
							options     = {utility.autoSelectOptions (customField.options, 'name', 'id')}
							name        = {field}
							searchable  = {true}
							placeholder = {placeholder}
							onChange    = {this.handleCustomSelect}
						/>
					);
				}
				case 'text_area':
					return (
						<TextField
							value       = {customField.value.replaceAll ("<br>", "")}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'text'
							multiline   = {true}
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'currency' :
					return (
						<TextField
							value       = {customField.value}
							error       = {errors[field]}
							variant     = 'outlined'
							name        = {field}
							type        = 'text'
							onChange    = {this.handleCustomFields}
							placeholder = {placeholder}
							fullWidth
						/>
					);
				case 'check_box' :
					return (
						<FormGroup row = {true}>
							{customField.options.map ((item, index) => (
								<FormControlLabel
									label   = {item.name}
									key     = {index}
									control = {<Checkbox checked = {customField.value.includes(item.name) ? true : false} name = {field} value = {item.name} onChange = {this.handleCustomCheckbox}/>}/>
							))}
						</FormGroup>
					);
				case 'radio_button':
					return (
						<FormControl component = "fieldset">
							<RadioGroup value = {customField.value} onChange = {this.handleCustomFields} row = {true} name = {field}>
								{customField.options.map ((item, index) => (
									<FormControlLabel value = {item.name}  label = {item.name} control = {this.renderRadio ()} key = {index}/>
								))}
							</RadioGroup>
						</FormControl>
					);
				case 'video':
					return (
						<VimeoVideoUploader
							videoName         = { this.state.profileInfo?.id || ''}
							onUploadCompleted = { this.handleVideoCustomFields }
							fieldName         = { field }
						/>
					);
				default :
					return;
			}
		}

		if (Array.isArray (value) && value.length) {
			return (
				<Typography variant = 'body1'> {value.join (', ')} </Typography>
			);
		}

		if (Array.isArray (value) && !value.length) {
			return (
				<Typography variant = 'body1'> {translations.profile.not_available[langCode]}</Typography>
			);
		}

		if (utility.isValidURL (value) && value.includes('vimeo')) {
			return (
				this.renderPrVideo (value)
			);
		}

		return (
			<Typography variant = 'body1'> {value ? ReactHtmlParser(value) : translations.profile.not_available[langCode]} </Typography>
		);
	}

	downloadFile = async (ev, document_id) => {
		let data = {
			applicant : {
				document_id : document_id,
			}
		};
		let link;
		try {
			link =  await apis.getDownloadLink (data);
		}
		catch (err) {
			log.error ({err}, 'error in downloading file');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		if (!link.download_url) {
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		return window.open (link.download_url);
	}

	renderDownloadDoc = () => {
		if (!this.state.downloadDoc || this.state.editable) {
			return;
		}
 
		let __downloadDocs = this.state.downloadDoc;
		let allDocs = Object.keys (__downloadDocs);
		if (!allDocs.length) {
			return;
		}
 
		let buttons = allDocs.map ((doc, index) => (
			<Grid container key = {index} spacing = {2} alignItems = 'center'>
				<Grid item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
					<Typography variant = 'subtitle2'> {doc} </Typography>
				</Grid>
				<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
					<Button color = 'primary' style = {{padding : 0}} onClick = {(ev) => this.downloadFile (ev, __downloadDocs[doc].document_id)}> Download </Button>
				</Grid>
			</Grid>
		));
		return buttons;
	}

	renderPrVideo = (pr_video) => {

		let vurl = pr_video;
		let vid = vurl.split ('/');
		vid = vid[vid.length - 1];
		let vsrc = pr_video.includes ('vimeo.com') ? `https://player.vimeo.com/video/${vid}` : pr_video;
		return (
			<div className='embed-container'>
				<iframe src={vsrc} frameBorder='0' allowFullScreen></iframe>
			</div>
		);
	}

	renderProfileInfo = () => {

		if (this.state.loading.profile) {
			return (
				<Skeleton />
			);
		}

		if (!this.state.profileInfo || !this.state.profileFields) {
			return;
		}

		let profileInfo   = this.state.profileInfo;
		let customFields  = profileInfo.custom_fields;
		let langCode      = this.state.langCode;
		let profileFields = this.state.profileFields;

		return (
			<Grid container direction='column' style = {{gap : 20}}>
				<Grid container spacing = {2} alignItems = 'flex-start'>
					<Grid container item xs = {5} sm = {5} md = {3} lg = {3} xl = {3}>
						<Typography variant = 'subtitle2'> {translations.profile.student_id[langCode]} </Typography>
					</Grid>
					<Grid item xs = {7} sm = {7} md = {3} lg = {3} xl = {3} className = 'pl-8'>
						{this.renderValues ('id', profileInfo.id)}
						{!this.state.editable ? <Divider className = 'mt-8'/> : null}
					</Grid>
				</Grid>
				<Grid container spacing = {2} alignItems = 'flex-start'>
					<Grid container item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
						{profileInfo['is_fastoffer'] ?
							<Typography variant = 'subtitle2'> {translations.apply.hiragana[langCode]} </Typography> :
							<Typography variant = 'subtitle2'> {translations.apply.name[langCode]} </Typography> }
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						{this.renderValues ('name', profileInfo.name)}
						{!this.state.editable ? <Divider className = 'mt-8'/> : null}
					</Grid>
				</Grid>
				{profileInfo.japanese_name ?
					<Grid container spacing = {2} alignItems = 'flex-start'>
						<Grid container item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
							<Typography variant = 'subtitle2'> {translations.apply.name[langCode]} </Typography>
						</Grid>
						<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
							{this.renderValues ('japanese_name', profileInfo.japanese_name)}
							{!this.state.editable ? <Divider className = 'mt-8'/> : null}
						</Grid>
					</Grid>
					: null}
				<Grid container spacing = {2} alignItems = 'flex-start'>
					<Grid container item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
						<Typography variant = 'subtitle2'> {translations.apply.email[langCode]} </Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						{this.renderValues ('email', profileInfo.email)}
						{!this.state.editable ? <Divider className = 'mt-8'/> : null}
					</Grid>
				</Grid>

				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
						<Typography variant = 'subtitle2'> {translations.profile.photo[langCode]} </Typography>
					</Grid>
					<Grid container item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8' alignItems = 'center'>
						{this.state.editable ?
							<Grid item xs = {12} sm = {12} md = {6}>
								<TextField
									type = 'file'
									name = 'photograph'
									onChange = {this.handlePhotoChange}
									inputProps = {{
										accept : "image/*"
									}}
								/>
							</Grid> : null}
						<Grid item xs = {12} sm = {12} md = {6}>
							<Avatar src = {this.state.editable && this.state.photoSrc ? this.state.photoSrc : profileInfo.photograph} style = {{width : 150, height : 150}} variant = 'square'/>
						</Grid>
					</Grid>
				</Grid>
				{!this.state.editable && profileInfo.pr_video ?
					<Grid container spacing={2} alignItems='flex-start'>
						<Grid container item xs={12} sm={12} md={3} lg={3} xl={3}>
							<Typography variant='subtitle2'> Video </Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={9} lg={9} xl={9} className='pl-8'>
							{this.renderPrVideo(profileInfo.pr_video)}
						</Grid>
					</Grid>
					: null}
				{this.state.editable && this.state.can_edit_pr_video ?
					<Grid container spacing = {2} alignItems = 'flex-start'>
						<Grid container item xs = {12} sm = {12} md = {3} lg = {3} xl = {3}>
							<Typography variant = 'subtitle2'> Video </Typography>
						</Grid>
						<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
							{/**Commenting out recorder part and adding upload functionality */}
							{/* <VideoRecorder
								elementId   = "profile-upload"
								width       = {720}
								height      = {480}
								payload     = {{applicant_id:profileInfo.id, request_type : 'pr_video'}}
								saveProfile = {this.onSave}
								requestType = 'pr_video'
							/>
							<Grid className = 'mt-16'>
								<Typography variant='body2' style = {{fontWeight : 'bold'}}> Instructions for students: </Typography>
								<ul style = {{listStyleType : 'inherit'}}>
									<li> Please press red ◉ icon on video to start the Video recording </li>
									<li> Once the video is recorded, you may play it back by pressing ▶ icon.</li>
									<li> In case you want to re-record the video, please press red icon and record it again.</li>
								</ul>
								<Typography variant = 'body2'>
									Note: Once the video is recorded and profile is SAVED, you cannot re-record. For more details contact AsiatoJapan support center
								</Typography>
							</Grid> */}
							<VimeoVideoUploader
								videoName         = {this.state.profileInfo?.id || ''}
								onUploadCompleted = {this.handlePRVideo}
								fieldName         = {'pr_video'}
							/>
						</Grid>
					</Grid>
					: null}
					
				{this.renderDownloadDoc ()}
				{/**
				 * Commenting the video upload functionality for now as it may be used in future, the code is moved to common component vimoeVideoUploader
				 * <VimeoVideoUploader />
				 */}

				{this.renderDocumentFields ()}
				<Grid>
					<Typography variant='subtitle1' className='mt-12 mb-12' align='center' style={styles.categortName}> {translations.apply.education_details[this.state.langCode]}</Typography>
					{this.state.editable && <Typography variant='h6' style = {{color : light.error.main}} className='mt-12 mb-12' align='center'> {translations.apply.only_add_msg[this.state.langCode]}</Typography>}
					{this.state.applicantUniversities.filter(uni => !uni._destroy).map ((university, index) => (
						<Grid key = {university.id} className='mb-8'>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
									<Typography variant='subtitle2'> {translations.apply.university[langCode]} </Typography>
									{this.state.editable &&
										<Button
											onClick   = {() => this.handleRemoveEducation (university.id)}
											disabled  = {this.state.applicantUniversities.filter(uni => !uni._destroy).length === 1}
											className = 'mt-4'
											variant   = 'outlined'
											style     = {styles.deleteButton}
											size      = 'small'
										>
											{translations.apply.remove_ed[langCode]}
										</Button>
									}
								</Grid>
								<Grid item xs={12} sm={12} md={9} lg={9} xl={9} className='pl-8' style = {{paddingRight : 40}}>
									{this.state.editable ?
										<>
											<AutoSelect
												value={university.university_id}
												options={utility.autoSelectOptions(this.state.universities, 'name', 'id')}
												name='university_id'
												searchable={true}
												onChange={(name, value) => this.handleUniversityFieldSelChange (name, value, university.id)}
												placeholder={translations.apply.university[langCode]}
												error = {this.state.universityError[index]?.university_id}
											/>
											<FormHelperText style={{ fontSize: 13 }}> {translations.apply.select_other_uni_msg[langCode]} </FormHelperText>
											{(university.otherUniversity || university.other_university_name) &&
												<Grid className='mt-16'>
													<TextField
														value={university.other_university_name || ''}
														name='other_university_name'
														onChange={(e) => this.handleUniversityFieldSelChange(e.currentTarget.name, e.currentTarget.value, university.id)}
														placeholder={translations.apply.otherUniversity[langCode]}
														variant='outlined'
														fullWidth
														error = {this.state.universityError[index]?.otherUniversity}
													/>
												</Grid>
											}
										</>
										:
										this.renderValues('university_name', university.other_university_name || university.university_name)
									}
								</Grid>
							</Grid>
							<Grid container spacing={2} alignItems='center' key = {university.id}>
								<Grid container item xs={1} sm={1} md={3} lg={3} xl={3}>
								</Grid>
								<Grid item xs={11} sm={11} md={9} lg={9} xl={9} className='pl-8' container spacing = {2}>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.education_backgrounds[langCode]} </Typography>
										{this.state.editable ?
											<AutoSelect
												value={university.education_background}
												options={utility.autoSelectOptions(Object.keys(this.state.education_backgrounds).map(item => {
													return {
														name : this.state.education_backgrounds[item],
														value : item,
													};
												}), 'name', 'value')}
												name='education_background'
												searchable={false}
												onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
												placeholder={translations.apply.education_backgrounds[langCode]}
												error = {this.state.universityError[index]?.education_background}
											/>
											:
											this.renderValues('education_background', this.state.education_backgrounds[university.education_background] || '')
										}
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.faculty[langCode]} </Typography>
										{this.state.editable ?
											<AutoSelect
												value={university.faculty}
												options={utility.autoSelectOptions(Object.keys(this.state.faculties).map(item => {
													return {
														name : this.state.faculties[item],
														value : item,
													};
												}), 'name', 'value')}
												name='faculty'
												searchable={true}
												onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
												placeholder={translations.apply.faculty[langCode]}
												error = {this.state.universityError[index]?.faculty}
											/>
											:
											this.renderValues('faculty', this.state.faculties[university.faculty] || '')
										}
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.department[langCode]} </Typography>
										{this.state.editable ?
											<TextField
												value={university.department || ''}
												name='department'
												onChange={(e) => this.handleUniversityFieldSelChange(e.currentTarget.name, e.currentTarget.value, university.id)}
												placeholder={translations.apply.department[langCode]}
												variant = 'outlined'
												fullWidth
											/>
											:
											this.renderValues('department', university.department)
										}
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3} container alignItems='center' >
										<Typography variant='subtitle2' className = 'mr-16'> {translations.apply.present[langCode]} </Typography>
										<Radio
											checked  = {university.is_latest || false}
											onChange = {(e) => this.handleUniversityFieldSelChange('is_latest', e.target.checked, university.id)}
											name     = 'is_latest'
											value    = {university.is_latest || false}
											disabled = {!this.state.editable}
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.start_month[langCode]} </Typography>
										{this.state.editable ?
											<AutoSelect
												value={university.start_month}
												options={utility.autoSelectOptions(utility.arrayRange(1, 12).map(item => {
													return {
														name : item,
														value : item,
													};
												}), 'name', 'value')}
												onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
												name='start_month'
												searchable={true}
												placeholder={translations.apply.start_month[langCode]}
												error = {this.state.universityError[index]?.start_month}
											/>
											:
											this.renderValues('start_month', moment(university.start_month, 'M').locale(this.state.langCode === 'jp' ? 'ja' : 'en' ).format('MMMM'))
										}
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.start_year[langCode]} </Typography>
										{this.state.editable ?
											<AutoSelect
												value={university.start_year}
												options={utility.autoSelectOptions(utility.arrayRange(1970, 2040).map(item => {
													return {
														name : item,
														value : item,
													};
												}), 'name', 'value')}
												name='start_year'
												onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
												searchable={true}
												placeholder={translations.apply.start_year[langCode]}
												error = {this.state.universityError[index]?.start_year}
											/>
											:
											this.renderValues('start_year', university.start_year)
										}
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.graduated_month[langCode]} </Typography>
										{this.state.editable ?
											<AutoSelect
												value={university.graduated_month}
												options={utility.autoSelectOptions(utility.arrayRange(1, 12).map(item => {
													return {
														name : item,
														value : item,
													};
												}), 'name', 'value')}
												name='graduated_month'
												searchable={true}
												onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
												placeholder={translations.apply.graduated_month[langCode]}
												error = {this.state.universityError[index]?.graduated_month}
											/>
											:
											this.renderValues('graduated_month', moment(university.graduated_month, 'M').locale(this.state.langCode === 'jp' ? 'ja' : 'en' ).format('MMMM'))
										}
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.graduation_year[langCode]} </Typography>
										{this.state.editable ?
											<AutoSelect
												value={university.graduated_year}
												options={utility.autoSelectOptions(utility.arrayRange(1970, 2040).map(item => {
													return {
														name : item,
														value : item,
														isDisabled : item <= university.start_year ? true : false,
													};
												}), 'name', 'value')}
												name='graduated_year'
												onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
												searchable={true}
												placeholder={translations.apply.graduation_year[langCode]}
												error = {this.state.universityError[index]?.graduated_year}
											/>
											:
											this.renderValues('graduated_year', university.graduated_year)
										}
									</Grid>
								</Grid>
							</Grid>
							{!this.state.editable ? <Divider className='mt-8' /> : null}
						</Grid>
					))}
					{this.state.editable &&
						<Grid className='mt-8' container justify='flex-start'>
							<Button variant='outlined' color = 'primary' onClick = {this.addEducation}> {translations.apply.add_education[langCode]}</Button>
						</Grid>
					}
				</Grid>
				
				{customFields.map ((category, index) => (
					<Grid key = {index} container direction='column' style = {{gap : 20}}>
						<Typography variant = 'subtitle1' className = 'mt-12 mb-12' align = 'center' style = {styles.categortName}> {category?.category_name} </Typography>
						{Object.keys (category.fields).map ((p, i) => (
							<Grid container spacing = {2} key = {i} alignItems = 'flex-start'>
								<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
									<Typography variant = 'subtitle2'> {langCode === 'en' && profileFields && profileFields[p] ? profileFields[p]['translated_name'] : p}
										{profileFields[p]['is_mandatory?'] ?
											<span style = {styles.required}>*</span>
											:
											null
										}
									</Typography>
								</Grid>
								<Grid item xs = {11} sm = {11} md = {8} lg = {8} xl = {8} className = 'pl-8'>
									{this.renderValues (p, customFields[index].fields[p])}
									{!this.state.editable ? <Divider className = 'mt-8'/> : null}
								</Grid>
								<Grid container alignItems='center' justify='flex-end' item xs={1} sm={1} md={1} lg={1} xl={1} className='pl-8'>
									{this.state.editable && profileFields[p]['help_text'] &&
										<Tooltip title={profileFields[p]['help_text']} arrow={true}>
											<InfoIcon />
										</Tooltip>
									}
								</Grid>
							</Grid>
						))}
					</Grid>
				))}
			</Grid>
		);
	}

	render () {
		
		let langCode = this.state.langCode;

		return (
			<Grid className = 'parent-content'>
				<TopNav name = '' mobileNav = {this.handleMobileNav}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing = {2}>
						<Grid container item xs = {5} sm = {6} md = {6} lg = {6} xl = {6} alignItems = 'center'>
							<Typography variant = 'h6' className = 'mb-4'> {translations.profile.my_profile[langCode]} </Typography>
						</Grid>
						<Grid item container justify = 'flex-end' alignItems = 'center' xs = {7} sm = {6} md = {6} lg = {6} xl ={6}>
							{this.renderActionButtons ()}
						</Grid>
					</Grid>
					<Divider className = 'mt-8' />
					<Paper className = 'p-24 mt-12' variant = 'outlined' style = {styles.form}>
						{this.renderProfileInfo ()}
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Profile);
